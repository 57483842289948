<template>
  <collapse title="Access tokens" class="is-relative">
    <b-message type="is-dark" class="has-margin-bottom-50">
      Site-level access tokens enable 'permitted systems' (such as first-party
      plugins) to securely retrieve details about your site and push updates
      back to our platform via an internal API – such as when a plugin, theme or
      configuration is updated.
    </b-message>

    <b-table
      :data="tokensArray"
      :mobile-cards="true"
      :loading="isLoading"
      backend-sorting
      striped
      class="is-size-7"
    >
      <template slot="empty">
        <no-results
          :cta="{ label: 'Generate token' }"
          title="No access tokens"
          message="This site has no active tokens"
          icon="box-open"
          @click="$store.dispatch('tokens/openAddTokenModal', { siteId })"
        />
      </template>

      <template
        slot-scope="props"
        :has-detailed-visible="typeof props.row.metadata === 'object'"
      >
        <b-table-column custom-key="token">
          <a
            :style="{ wordBreak: 'break-all' }"
            class="has-text-dark"
            @click="$bus.$emit('copyToClipboard', `${props.row.token}`)"
          >
            <u>{{ props.row.token }}</u>
          </a>
        </b-table-column>

        <b-table-column
          custom-key="dates"
          field="dateCreated"
          width="240"
          numeric
        >
          <p>
            Uses: <strong>{{ props.row.totalRequests || 0 }}</strong>
          </p>
          <p>
            Created:
            {{
              $moment(props.row.dateCreated.toDate()).format("DD/MM/YY HH:mm")
            }}
          </p>
          <p>
            Expires:
            {{
              props.row.dateExpires
                ? $moment(props.row.dateExpires.toDate()).format(
                    "DD/MM/YY HH:mm"
                  )
                : "Never"
            }}
          </p>
        </b-table-column>

        <b-table-column numeric label="">
          <b-dropdown
            position="is-bottom-left"
            class="has-text-left"
            :mobile-modal="false"
          >
            <a slot="trigger" class="has-text-dark">
              <b-icon icon="cog" />
            </a>

            <b-dropdown-item @click="openCallsModal(props.row)"
              >View call logs</b-dropdown-item
            >
            <b-dropdown-item
              class="has-text-danger"
              @click="revokeToken(props.row)"
              >Revoke token</b-dropdown-item
            >
          </b-dropdown>
        </b-table-column>
      </template>
    </b-table>

    <button
      v-if="!$_.isEmpty(tokens)"
      class="button has-margin-top-150"
      type="button"
      @click="$store.dispatch('tokens/openAddTokenModal', { siteId })"
    >
      Add token
    </button>
    <b-loading :active="isProcessing" :is-full-page="false" />
  </collapse>
</template>

<script>
export default {
  name: "SiteTokens",
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      isProcessing: false
    };
  },
  computed: {
    scope() {
      return `$site_${this.siteId}`;
    },
    site() {
      return this.$store.getters["sites/site"](this.siteId) || {};
    },
    tokens() {
      return this.$store.getters["tokens/tokens"]({ scope: this.scope });
    },
    tokensArray() {
      return this.$_(this.tokens)
        .orderBy([t => t.dateCreated.toDate()], ["desc"])
        .toArray()
        .value();
    }
  },
  async created() {
    await this.$store.dispatch("sites/observeSite", {
      siteId: this.siteId
    });
    await this.$store.dispatch("tokens/observeSiteTokens", {
      siteId: this.siteId
    });
    this.isLoading = false;
  },
  beforeDestroy() {
    this.$store.dispatch("tokens/unobserveSiteTokens", { siteId: this.siteId });
    this.$store.dispatch("sites/unobserveSite", {
      siteId: this.siteId
    });
  },
  methods: {
    openCallsModal(token) {
      this.$modal.open({
        component: () => import("@shared/tokens/_callLogsModal"),
        parent: this,
        width: 960,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          tokenRef: token._ref,
          height: 480
        }
      });
    },
    revokeToken(token) {
      this.$confirm.open({
        parent: this,
        props: {
          action: "Revoke access token",
          message:
            "Are you sure you want to revoke this access token? This cannot be undone."
        },
        events: {
          confirmed: () => {
            this.isProcessing = true;
            this.$store
              .dispatch(`tokens/revokeToken`, token._ref)
              .then(() => {
                this.$toast.open({
                  message: "Token revoked"
                });
              })
              .catch(error => {
                this.$toast.open({
                  message: error.message,
                  type: "is-danger"
                });
              })
              .finally(() => {
                this.isProcessing = false;
              });
          }
        }
      });
    }
  }
};
</script>
